.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("../public/rothko.jpg");
  background-position: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

button {
  all: unset;
}

.link-to-paid{
  margin: 20px;
  padding-top : 3px;
  padding-bottom: 3px;
  padding-right: 5px;
  padding-left: 5px;
  border:1px solid green;
  font-size: 20px;
}

.App-link {
  color: #61dafb;
}

.loading-image{
  height: 40px
}

.timer{
width: 500px

}

.inputBox{
  height:200px;
  width:200px;
  margin: 50px;
}

.serveButton{
  background-color:white;
  color: #282c34;
  padding: 10px 25px;
  border-radius: 20px;
  outline: 2;
  border: 2; 
  margin: 10px 0px;
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 16px;
  border-color: white;
  font-weight: bold;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
